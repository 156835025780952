<template>
  <div class="container-fluid ContainerNew">
      <div class="row heightFull position-relative">
        <div class="form-Wrapper pt-3 p-5 rounded-5 bg-jexa form-Wrapper pt-3 p-5 rounded-5">
          <div class="logo d-flex justify-content-center mb-4">
              <img src="../../assets/images/jexa-logo.png" alt="" width="300">
            </div>
              <div class="login-heading mb-3 text-white">
                <h1 class="mb-0 pb-2 fs-5 text-white">Welcome to <span style="color:var(--blueN)">Jexa Admin</span> </h1>
                <h2 class="mb-0 fs-1 fw-semibold text-white">Sign in</h2>
              </div>
  
              <div class="form-box">
                <form class="row form-row">
                  <div class="col-12 col-sm-12 mb-33">
                    <div class="label-box text-white">
                      <label class="text-white"> Login Id or Email Address</label> 
                    </div>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control shadow-none"
                        placeholder="Username or email address"
                        aria-label="email"
                        aria-describedby="basic-addon1"
                        v-model="email"
                      />

                    </div>
                    <span class="error_msg" v-if="v$.email.$error">
                        {{ v$.email.$errors[0].$message }}
                    </span>
                  </div>

                  <div class="col-12 col-sm-12 mb-33">
                    <div class="label-box text-white">
                      <label class="text-white"> Password</label>
                    </div>
                    <div class="input-group mb-2 position-relative">
                      <input
                        :type="hidden ? 'password' : 'text'"
                        class="form-control shadow-none rounded"
                        placeholder="Password"
                        aria-label="password"
                        aria-describedby="basic-addon1"
                        v-model="password"
                      />

                      <span class="input-group-text border-0" id="basic-addon1">
                        <img
                          :src="hidden ? hide : show"
                          alt=""
                          class="img-fluid"
                          style="cursor: pointer"
                          @click="hidden = !hidden"
                        />
                      </span>
                    </div>
                    <span class="error_msg" v-if="v$.password.$error">
                        {{ v$.password.$errors[0].$message }}
                    </span>
                     
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="submit-button">
                      <button class="btn btn-primary " type="submit" v-show="!loader"  @click.prevent="login">
                        Log In
                      </button>
                      <button class="btn btn-primary" v-show="loader">
                        <div
                          class="spinner-border text-light"
                          role="status"
                        ></div>
                      </button>
                    </div>
                  </div>
                </form>
              <div class="text-center my-4 "></div> 
              
              </div>
              <!--form-box-->
        </div>
      </div>
  </div>
</template>

<script>
import hide from "@/assets/images/icon/hide.png";
import show from "@/assets/images/icon/show.png";
import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import ApiClass from '../../api/api.js';

export default {
  name: "LoginView",

  data() {
    return {
      loader: false,
      hidden: true,
      hide: hide,
      show: show,
      email: '',
      password: ''
    };
  },
  setup() {
    return {
        v$: useVuelidate(),
    };
  },
  mounted() {
      this.$store.commit('SET_R_KEY', '');
      this.$store.commit('SET_VM', '');
      this.$store.commit('SET_USER_EMAIL', '');
      this.$store.commit('SET_USER_MOBILE', '');
      this.$store.commit('SET_USER_UNIQUE_ID', '');
      this.$store.commit('SET_LOGIN_TYPE', "");
      this.$store.commit('SET_WALLET_ADDRESS', "");
      this.$store.commit('USER_DETAIL', {});
  },
  validations() {
      return {
          email: {
              required: helpers.withMessage("Login Id is Required", required)
          },
          password: {
              required: helpers.withMessage("Password is Required", required)
          }
      };
  },
  methods: {
    async login() {
        const result = await this.v$.$validate();

        if (!result) {
            return;
        }

        if (this.v$.$invalid) {
            return;
        }

        this.loader = true;

        let body = {
            "unique_id": this.email,
            "password": this.password
        };

        let response = await ApiClass.postRequest("user/admin_login", false, body);

        if (response?.data?.status_code != 1) {
            this.loader = false;
            this.failed(response?.data?.message);
            return;
        }

        this.loader = false;
        this.success(response?.data?.message);

        if (response.data.data.v == true) {
            this.$store.commit('SET_R_KEY', response?.data?.data?.r_key)
            this.$store.commit('SET_VM', response?.data?.data?.vm)
            this.$store.commit('SET_USER_EMAIL', response.data?.data.email);
            // this.$store.commit('SET_USER_MOBILE', response.data?.data.mobile);
            this.$store.commit('SET_USER_UNIQUE_ID', response.data?.data.unique_id);
            this.$store.commit('SET_LOGIN_TYPE', "email");
            this.$router.push('/otp');
        } else {
            localStorage.setItem('token', response.data.data.token)
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            this.$store.commit('SET_USER_DETAIL', response.data.data.user); 
            this.$store.commit('SET_WALLET_ADDRESS', response.data?.data.user.wallet_address);
            this.$router.push('/dashboard');
            return;
        }
    }
}
};
</script>

<style scoped>
.forgot {
  text-align: end;
}
.forgot a {
  color: var(--blueN);
  font-size: 14px;
  font-weight: 500;
}
.heightFull{
  background: linear-gradient(90deg, #011726, 24.0826%, #046 48.1651%, 74.0826%, #001827);
}

body .form-Wrapper {
    max-width: 500px !important;
    margin: auto;
}

.bg-jexa {
    background-color: #5bffee1f;
    color: white;
}
.form-Wrapper {
    box-shadow: -1px 0 4px rgba(0, 0, 0, .25);
    margin: auto;
}
:is(body .form-control,.form-select,) {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    height: 50px;
    background: #5bffee1f;
    border: 1px solid #5bffee38;
}
input::placeholder{
  color: white;

}

.btn-primary {
    background: linear-gradient(90deg, #4bb9cf, 6.97674%, #44b4ce 13.9535%, 56.9767%, #2499c3);
    padding: 5px 15px;
    text-align: center;
    color: #fff;
    border-radius: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    border: none !important;
    box-shadow: unset !important;
}
</style>
