<template>
<div>
    <Header @toggle-subscribe="subscribeTvShow" v-if="$route.meta.authOnly" />
    <div class="container-fluid">
        <div class="row heightFull">
            <div class="col-lg-2 ps-0 pe-0 CustomSidebar" :class="{
            Newsidebar: showSidebar,
            'collapsed-sidebar': !showSidebar,
          }" id="cc-side">
                <sidebar v-if="$route.meta.authOnly" />
            </div>
            <div class="col-md-12 col-lg-10 px-0" :class="{
            'col-lg-10': showSidebar,
            'col-lg-12': !showSidebar,
            'px-0': showSidebar,
          }">
                <router-view />
            </div>
        </div>

    </div>
</div>
</template>

<script>
import Header from "./components/Header.vue";
import Sidebar from "./components/Sidebar.vue";

export default {
    name: "App",
    data() {
        return {
            showSidebar: false,
        };
    },
    components: {
        Header,
        Sidebar
    },
    methods: {
        subscribeTvShow(showId) {
            this.showSidebar = showId;
        }
    },
};
</script>

<style>
body {
    background-color: var(--bg-view) !important;
}

#app {
    font-family: Poppins, Helvetica, Arial, sans-serif;
}

:root {
    --bg-view: #f6f8fe;
    --main-bg: #f7f7f7;
    --white-bg: #fff;
    --black-bg: #fff;
    --border: #d6d9df;
    --bg-1: #f2f6ff;
    --yellow-bg: #e45d1b;
    --white: #fff;
    --black: #000;
    --black-2: #c9c8c8;
    --grey: #888888;
    --grey2: #808080;
    --border: #dddddd;
    --green: #44b4ce;
    --red: #d90429;
    --btn: #262391;
    --dark-blue: #0e4051;
    --lot-green: #44b4ce;
    --lot-red: #be5150;
    --lot-bg-green: #e7f8f2;
    --lot-bg-red: #f9ebea;
    --lot-datetime: #b8bdc9;
    --text-muted: #8c9097;
    --raxios-muted: linear-gradient(90deg,
            rgba(85, 115, 179, 1) 95%,
            rgba(85, 115, 179, 1) 100%);

    --raxios-color: rgb(85, 115, 179);

    --raxios-btn-color: #e49323;
    --blueN: #44b4ce;
    --Orange: #e49323;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-SemiBold.ttf);
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-ExtraBold.ttf);
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Bold.ttf);
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: Poppins;
    src: url(./assets/fonts/Poppins-Black.ttf);
    font-weight: 900;
    font-display: swap;
}

/**====================================
          AUTH SEC CSS START
=======================================**/

section.auth-sec {
    /* min-height: 100vh; */
    display: flex;
}

body {
    background-color: #0b4558 !important;
}

section.auth-sec .container {
    padding: 25px 20px 0px 20px;
}

input::placeholder {
    color: #ddd;
}

.btn-succmain {
    background-color: #44b4ce !important;
    color: #fff !important;
}

.auth-image {
    text-align: center;
}

.auth-image img {
    max-width: 70%;
}

.login-heading h1 {
    color: var(--black-2);
    font-size: 24px;
    font-weight: 600;
}

.login-heading p {
    font-size: 14px;
    font-weight: 400;
    color: var(--grey);
}

.label-box label {
    font-size: 14px;
    font-weight: 600;
    color: var(--black-2);
    /* margin-bottom: 13px; */
}

:is(body .form-control, .form-select) {
    font-size: 14px;
    font-weight: 400;
    color: var(--black-2);
    /* padding: 19px 25px; */
    height: 50px;
}

body .form-control::placeholder {
    color: var(--grey2);
    font-size: 14px;
    font-weight: 400;
}

body .form-control:focus {
    background-color: var(--blueN);
    border-color: var(--blueN);
    box-shadow: none;
}

.table>:not(caption)>*>* {
    background-color: #0c4354 !important;
    color: #ddd !important;
    font-weight: 400 !important;
    font-size: 14px;
}

:is(.currency-text h5, .currency-text span) {
    color: #ddd !important;
    font-weight: 400 !important;
}

.card {
    background-color: #0c4354 !important;
    color: #fff !important;

}

table a {
    color: #44b4ce !important;
    font-size: 14px;
}

.bg-succ {
    background-color: #44b4ce !important;
    font-weight: 400 !important;

}

.bg-second {
    background-color: #e8a505 !important;
    font-weight: 400 !important;

}

.submit-button .btn-primary {
    width: 100%;
}

a {
    color: #fff !important;
}

body .btn-primary {
    background-color: var(--blueN);
    padding: 5px 25px;
    text-align: center;
    transition: 0.5s;
    color: #fff;
    /* box-shadow: 0 0 20px #eee; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    border: 1px solid var(--blueN) !important;
    transition: all 0.5s ease-in-out;
}

body .btn-primary:hover {
    background-color: #fff;
    color: var(--blueN);
}

.spinner-border {
    --bs-spinner-width: 1rem !important;
    --bs-spinner-height: 1rem !important;
}

.auth-text p {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: var(--grey2);
    letter-spacing: 0.8px;
}

.error_msg {
    color: red;
    font-size: 10px;
    font-weight: 500;
}

/**================ OTP INPUT FIELD CSS START ================ **/

.modal-content {
    background-color: #0b4558 !important;

}

.otp-input div {
    justify-content: space-between;
    width: 100%;
}

input.otp-input {
    min-height: 20px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid var(--grey-2);
    font-weight: 500;
    background-color: var(--white-bg);
    width: 40px;
    box-shadow: -1px 0px 3px rgb(0 0 0 / 25%);
    padding: 10px;
    margin-left: 3px;
    border: 1px solid #adadad;
}

input.otp-input:focus-visible {
    outline: none;
}

.mb-33 {
    margin-bottom: 33px;
}

/* auth pages */
.heightFull {
    height: 100vh;
}

.ContainerNew::before {
    content: "";
    background: var(--blueN);
    width: 50%;
    height: 100vh;
    display: inline-block;
    position: absolute;
    left: 0;
    z-index: -1;
}

.form-Wrapper {
    box-shadow: -1px 0px 4px rgb(0 0 0 / 25%);
    margin: auto;
}

.auth-text a,
.textNew a {
    color: var(--blueN);
}

.auth-text a:hover {
    text-decoration: none !important;
}

body .form-check-input:checked {
    background-color: var(--blueN);
    border-color: var(--blueN);
}

@media screen and (max-width: 767px) {
    .ContainerNew {
        padding: 0 20px;
    }

    .ContainerNew::before {
        width: 100%;
    }
    .heightFull{
        height: 100%
    }
}

/* Sidebar code */
.CustomSidebar.collapsed-sidebar {
    display: none;
}

.CustomSidebar .router-link-active {
    background-color: var(--blueN);
    color: #fff !important;
}

.CustomSidebar .router-link-active img {
    /* filter: invert(100%) sepia(99%) saturate(2%) hue-rotate(12deg)
    brightness(112%) contrast(101%); */
}

.cardTrade {
    padding: 24px 35px;
    background-color: #5bffee1f !important;
    color: white;
    transition: all 0.5s ease-in-out;
    border: 0rem solid transparent;
    border-radius: 1.25rem;
    /* box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05); */
    box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
}

.coinType span {
    width: 40px;
    height: 40px;
}

.greenBg span {
    background-color: #32a9544d;
}

.redBg span {
    background-color: rgb(216 24 24 / 15%);
}

:is(.currency-text h5, .currency-text span) {
    font-size: 14px;
    color: var(--black);
    font-weight: 500;
}

.textGrey {
    color: #adadad !important;
}

.cursorPointer {
    cursor: pointer;
}

/* ===========PAGINATION CSS START============== */

.pagination {
    justify-content: end !important;
    background-color: transparent !important;
}

.page-link {
    z-index: 3 !important;
    color: var(--blueN) !important;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 2px;
    border-color: var(--blueN) !important;
    font-size: var(--fs-15) !important;
}

.page-item.active .page-link {
    z-index: 3 !important;
    color: var(--white) !important;
    box-shadow: none !important;
    background-color: var(--blueN) !important;
    border-color: var(--blueN) !important;
}

.pagination_box ul li {
    border: none !important;
    margin-bottom: 20px !important;
    margin-bottom: 0 !important;
    color: var(--lot-green);
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
    box-shadow: none !important;
}

.VuePagination__pagination {
    /* box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px !important; */
    background-color: var(--yellow-bg);
    color: var(--yellow-bg);
    gap: 4px;
}

.VuePagination nav p {
    color: #c1c0c0;
    font-size: var(--fs-14);
}

/* ===========PAGINATION CSS FINISH============== */
.img_log {
    border: 2px solid #ffffff;
    max-width: 120px;
    padding: 32px 0;
    border-radius: 50%;
}

.btn-close {
    background-color: #fff !important;
}

.input-group input+span {
    position: absolute;
    right: 3px;
    top: 7px;
    z-index: 10;
    background-color: #0000;
    border: 0;
}

@media screen and (max-width:767px) {
    .refer-link {
        width: 100% !important;
    }

    .cardTrade {
        padding: 20px;
    }

    .level__income th {
        white-space: pre;
    }

    .left-side {
        width: 100%;
    }

    .logo img {
        width: 170px;
    }

    body .pagination_box nav {
        flex-direction: column-reverse;
        gap: 12px;
    }

}

.security-form {
    max-width: 690px;
    margin: auto;
}

.pagination_box .VuePagination {
    width: 100%;
}

.pagination_box nav {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.pagination_box nav ul,
.VuePagination nav p {
    margin: 0;
}

body .form-Wrapper {
    max-width: 500px !important;
    margin: auto;
}

input.otp-input {
    max-width: 60px;
    width: 99%;
    height: 60px;
    margin: 0;
    border-color: var(--white);
}

input.otp-input:focus {
    border-color: var(--blueN) !important;
    background-color: var(--blueN) !important;
}

.tree-container {
    width: 100% !important;
    /* border: 1px solid var(--active) !important; */
    overflow: scroll !important;
    direction: ltr;

}

.textgreen>span {
    color: green;
}

.textred>span {
    color: red;
}

.card-body {
    min-height: 150px;
}

.detail-card-img img {
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.dash-card {
    background-color: #191c24;
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    backdrop-filter: blur(20px);
    border: 1px outset rgba(255, 255, 255, 0.3);
    /* background: linear-gradient(145deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%); */
    padding: .8rem;
    height: 100%;
}

.card-btn {
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    border-radius: 8px;
    backdrop-filter: blur(20px);
    border: 1px outset rgba(255, 255, 255, 0.3);
    font-size: 16px;
    color: #fff;
    font-weight: 600;
}

.card-btn img {
    height: 50px;
}

.card-icon {
    box-shadow: 0px 1px 24px -1px rgba(0, 0, 0, 0.2);
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.1) 100%);
    padding: 1rem;
    border-radius: 50%;
    backdrop-filter: blur(20px);

}

.main-bg {
    background-color: #5bffee1f !important;
    color: white;
    box-shadow: -1px 0 4px rgba(0, 0, 0, .25);
    height: 180px !important;
    border: 1px solid #015c78 !important;

}

.jexa-bg {
    background-color: #0b4558 !important;
    color: white !important;
}

.card-bg-3 {
    background-image: linear-gradient(to top, #324e8c 0%, #00a1c0 100%);
}

.card-bg-2 {
    background-image: linear-gradient(90deg, #011726, 24.0826%, #046 48.1651%, 74.0826%, #001827);
}

.card-bg-4 {
    background-image: linear-gradient(to top, #011726 0%, #fbc2eb 100%);
}

.card-bg-5 {
    background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%);
}

.card-bg-6 {
    background-image: linear-gradient(to top, #9f79ea 0%, #80aeff 100%);
}

.card-bg-7 {
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
}

.card-bg-8 {
    background-image: linear-gradient(to right, #648eeb 0%, #9f79ea 100%);
}

.card-bg-9 {
    background-image: linear-gradient(120deg, #564874 0%, #2f5386 100%);
}

.card-bg-1 {
    background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
}

.card-bg-11 {
    background-image: linear-gradient(to right, #a8caba 0%, #5d4157 100%);
}

.card-icon img {
    height: 32px;
    width: 32px;
}

.heightFull {
    background: linear-gradient(90deg, #011726, 24.0826%, #046 48.1651%, 74.0826%, #001827);
    /* background-color: #01293f; */
}

.bg-gcard {
    background: linear-gradient(90deg, #011726, 24.0826%, #046 48.1651%, 74.0826%, #001827);

}

.text-gray {
    color: #cfcfcf !important;
}

.accordion-button {
    background-color: #0b4558 !important;
    color: #fff;
}

.accordion {
    background-color: #0b4558 !important;
}

.accordion-item {
    background-color: #0b4558 !important;

}

.table {
    background-color: #0b4558 !important;
    color: #000 !important;
}

:is(body .form-control, .form-select, ) {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    height: 50px;
    background: #5bffee1f;
    border: 1px solid #5bffee38;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #0b4558 !important;
}


input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

</style>
