import { createRouter, createWebHistory } from "vue-router";
import LoginView from "../views/auth/LoginView.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView, 
    // meta: { authOnly: false }
  },
 
  {
    path: "/otp",
    name: "otp",
    component: () =>
      import(/* webpackChunkName: "otp" */ "../views/auth/OTPVerification.vue"),
      // meta: { authOnly: false }
  },
  

  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/DashboardView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/withdraw-history",
    name: "WithdrawHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/WithdrawHistory.vue"),
      meta: { authOnly: true }
  },
   

  {
    path: "/deposit-history",
    name: "DepositHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/DepositHistory.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/user-history",
    name: "UserHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UserHistory.vue"),
      meta: { authOnly: true }
  },
   
   
  {
    path: "/fund-history",
    name: "FundTransferHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/FundTransferHistory.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/security",
    name: "security",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/SecurityView.vue"),
      meta: { authOnly: true }
  },

  
  
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/ProfileView.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/members/:member?",
    name: "members",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/MembersView.vue"),
      meta: { authOnly: true }
  },

  

  {
    path: "/level-members",
    name: "LevelMembers",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/LevelMembers.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/direct-members",
    name: "DirectMembers",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/DirectMembers.vue"),
      meta: { authOnly: true }
  },
  {
    path: "/generation",
    name: "GenerationTree",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/members/GenerationTree.vue"),
      meta: { authOnly: true }
  },

  // NEW ROUTES 


  // {
  //   path: "/fund-transfer",
  //   name: "FundTransfer",
  //   component: () =>
  //     import(/* webpackChunkName: "terminate" */ "../views/FundTransfer.vue"),
  //     meta: { authOnly: true }
  // },
  {
    path: "/user-setting",
    name: "userSetting",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/UserSetting.vue"),
      meta: { authOnly: true }
  },

  {
    path: "/topup-history",
    name: "TopUpHistory",
    component: () =>
      import(/* webpackChunkName: "terminate" */ "../views/TopUpHistory.vue"),
      meta: { authOnly: true }
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");
  const isAuth = to.matched.some((record) => record.meta.authOnly);
  
  if(to.fullPath == '/termcondition'){
    return next();
  }
   

  if (isAuth && !loggedIn) {
    return next({ path: "/" });
  } else if (!isAuth && loggedIn) {
    return next({ path: "/dashboard" });
  } else if (!isAuth && !loggedIn) {
    return next();
  }
  next();
});

export default router;
